import React, { useState } from "react";

import { Layout, Menu, Image } from "antd";

import {
  StockOutlined,
  HomeOutlined,
  BulbOutlined,
  StarOutlined,
  BellOutlined,
  SmileOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ShoppingCartOutlined,
  AlertOutlined,
  LogoutOutlined,
  TrophyOutlined
} from "@ant-design/icons";

import { getAuth, signOut } from "firebase/auth";

import "./MenuAdmin.css";
import "antd/dist/antd.css";

const { Sider } = Layout;

const MenuAdmin = ({ handleClick, collapsed, onCollapse }) => {


  const auth = getAuth();

  return (
    <Sider style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
    }} collapsible 
    collapsed={collapsed} 
    onCollapse={onCollapse}>
      <div className="logo">
        <Image
          height={30}
          src="https://firebasestorage.googleapis.com/v0/b/studially-2790e.appspot.com/o/logos%2FlogoStudially.png?alt=media&token=ba33fb67-e28d-44ba-90aa-6d075ca06265"
        />
      </div>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item key="1" icon={<HomeOutlined />} onClick={handleClick}>
          Usuarios
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />} onClick={handleClick}>
          Administradores
        </Menu.Item>
        <Menu.Item key="3" icon={<BulbOutlined />} onClick={handleClick}>
          Oportunidades
        </Menu.Item>

        <Menu.Item key="4" icon={<StarOutlined />} onClick={handleClick}>
          Salud Mental
        </Menu.Item>
        <Menu.Item key="5" icon={<StockOutlined />} onClick={handleClick}>
          Aprendizaje
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<UsergroupAddOutlined />}
          onClick={handleClick}
        >
          Comunidad
        </Menu.Item>
        <Menu.Item key="7" icon={<SmileOutlined />} onClick={handleClick}>
          Hábitos
        </Menu.Item>
        <Menu.Item
          key="8"
          icon={<ShoppingCartOutlined />}
          onClick={handleClick}
        >
          Rewards
        </Menu.Item>
        <Menu.Item key="9" icon={<AlertOutlined />} onClick={handleClick}>
          Orders
        </Menu.Item>

        <Menu.Item key="10" icon={<TrophyOutlined />} onClick={handleClick}>
          Aplicaciones
        </Menu.Item>

        {/* <Menu.Item key="7" icon={<BellOutlined />} onClick={handleClick}>
          Notificaciones
        </Menu.Item> */}

        <Menu.Item
          key="11"
          icon={<LogoutOutlined />}
          onClick={() => {
            signOut(auth)
              .then(() => {
                console.log("User signed out");
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default MenuAdmin;
