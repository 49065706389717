import { doc, setDoc } from "@firebase/firestore";
import { firestore } from "../firebaseConfig";

import { v4 as uuidv4 } from "uuid";

const handleSubmit = (coll, info) => {
  const id = uuidv4();
  const ref = doc(firestore, coll, id); // Firebase creates this automatically
  let data = info;
  data.id = id;
  try {
    setDoc(ref, data);
  } catch (err) {
    console.log(err);
  }
};
export default handleSubmit;
