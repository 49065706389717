import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
//style
import "antd/dist/antd.css";

//Components
import MenuAdmin from "./components/MenuAdmin/MenuAdmin";

//Pages
import Inicio from "./pages/Inicio/Inicio";
import Aprendizaje from "./pages/Aprendizaje/Aprendizaje";
import SaludMental from "./pages/SaludMental/SaludMental";
import Comunidad from "./pages/Comunidad/Comunidad";
import Oportunidades from "./pages/Oportunidades/Oportunidades";
import Habitos from "./pages/Habitos/Habitos";
import Notificaciones from "./pages/Notificaciones/Notificaciones";
import Enfoque from "./pages/Enfoque/Enfoque";
import EnfoquePedidos from "./pages/Enfoque/EnfoquePedidos";
import Administradores from "./pages/Administradores/Administradores";

//Libs
import handleLogin from "./handlers/handleLogin";

import { Layout, Input, Button, Image, notification } from "antd";
import { getAuth } from "firebase/auth";
import Aplicaciones from "./pages/Aplicaciones/Aplicaciones";
const { Content } = Layout;

function App() {
  const components = {
    1: <Inicio />,
    2: <Administradores />,
    3: <Oportunidades />,
    4: <SaludMental />,
    5: <Aprendizaje />,
    6: <Comunidad />,
    7: <Habitos />,
    //7: <Notificaciones />,
    8: <Enfoque />,
    9: <EnfoquePedidos />,
    10: <Aplicaciones />,
  };

  const [render, updateRender] = useState(1);

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [loggedIn, setLoggedIn] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleMenuClick = (menu) => {
    updateRender(menu.key);
  };

  const Context = React.createContext({ name: "Default" });

  const [api, contextHolder] = notification.useNotification();

  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const openNotification = (type) => {
    if (type === "success") {
      api.success({
        message: `Operación exitosa`,
      });
    } else if (type === "error") {
      api.error({
        message: `Inicio de sesión incorrecto`,
      });
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  

  if (loggedIn === undefined) {
    return null;
  }

  return (
    
    <div>
      {!loggedIn ? (
        <div class="container-fluid">
          <Context.Provider value={contextValue}>
            {contextHolder}
          </Context.Provider>
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />
          <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous">
          </script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous">
          </script>
          <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous">
          </script>
          <div class="row no-gutter">
            <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
            <div class="col-md-8 col-lg-6">
              <div class="login d-flex align-items-center py-5 w-100">
                <div class="container">
                  <div class="row">
                    <div class="col-md-9 col-lg-8 mx-auto">
                      <div class="text-center">
                        <Image
                          width={150}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                          }}
                          src="https://firebasestorage.googleapis.com/v0/b/studially-2790e.appspot.com/o/logos%2FlogoST.png?alt=media&token=15a21e44-4e4d-49a9-8458-8778896ebee6"
                        />
                        <h3 class="login-heading mb-5 mt-2" align="center">¡Bievenido de nuevo!</h3>
                      </div>
                      <form>
                        <div class="form-label-group">
                          <Input onChange={(event) => setUser(event.target.value)} type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus />
                          <label for="inputEmail">Correo electrónico</label>
                        </div>
                        <div class="form-label-group">
                          <Input onChange={(event) => setPass(event.target.value)} type="password" id="inputPassword" class="form-control" placeholder="Password" required />
                          <label for="inputPassword">Contraseña</label>
                        </div>

                        <Button loading={false}
                          onClick={async () => {
                            const login = await handleLogin(user, pass);
                            if (!login) {
                              openNotification("error");
                            } else {
                              setLoggedIn(true);
                            }
                          }} className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" style={{
                            height: '60px',
                            padding: '0 15px',
                            borderRadius: '10px',
                            fontSize: '1rem'
                          }} type="primary" block >Iniciar Sesión</Button>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Layout style={{ minHeight: "100vh", display: 'flex', flexDirection: 'row' }}>
    <MenuAdmin 
        style={{ position: 'fixed', zIndex: 1000 }}
        handleClick={handleMenuClick} 
        collapsed={collapsed} 
        onCollapse={onCollapse} 
    />
    <Layout style={{ marginLeft: collapsed ? 80 : 200, overflowX: 'auto' }}>
        <Content style={{ margin: "0 16px", padding: '0 0 0 16px' }}>
            {components[render]}
        </Content>
    </Layout>
</Layout>

      )}
    </div>
  );
}

export default App;
