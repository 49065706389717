import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
} from "firebase/auth";
import handleRead from "./handleRead";
const auth = getAuth();
const handleLogin = async (email, password) => {
  const adminsR = await handleRead("administradores");
  const admins = adminsR.map((admin) => admin.correo);
  if (admins.includes(email)) {
    try {
      await setPersistence(auth, browserLocalPersistence).then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  } else {
    return false;
  }
};
export default handleLogin;
