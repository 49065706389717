import { doc, deleteDoc } from "@firebase/firestore";
import { firestore } from "../firebaseConfig";
const handleDelete = (coll, id) => {
  const ref = doc(firestore, coll, id); // Firebase creates this automatically
  try {
    deleteDoc(ref);
  } catch (err) {
    console.log(err);
  }
};
export default handleDelete;
