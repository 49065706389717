import React, { useState, useEffect, useMemo, useRef } from "react";
import handleRead from "../../handlers/handleRead";
import handleDelete from "../../handlers/handleDelete";
import handleUpdate from "../../handlers/handleUpdate";

import Highlighter from "react-highlight-words";

//Style
import "./Inicio.css";
import "antd/dist/antd.css";

//Antd
import {
  Layout,
  Breadcrumb,
  Input,
  Select,
  Button,
  Table,
  Tag,
  Space,
  Modal,
  notification,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer } = Layout;
const { confirm } = Modal;

const Inicio = () => {
  const [visible, setVisible] = useState(false);

  const [data, setData] = useState([]);

  // data to save
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [tuser, setTuser] = useState("");
  const [since, setSince] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [fuegos, setFuegos] = useState("");
  const [minutosTotales, setMinutosTotales] = useState("");
  const [estrellas, setEstrellas] = useState("");
  const [minutos, setMinutos] = useState({});
  const [habitos, setHabitos] = useState([]);

  // id to find it in database
  const [id, setId] = useState("");

  const Context = React.createContext({ name: "Default" });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.success({
      message: `Operación exitosa`,
    });
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  const showConfirm = (id) => {
    confirm({
      title: "¿Seguro que quieres eliminar al usuario?",
      icon: <ExclamationCircleOutlined />,
      content: "El usuario ya no podrá acceder a los beneficios de Studially",
      onOk() {
        try {
          handleDelete("usuarios", id);
          openNotification();
          readData();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  /***************************Filters**********************/

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /***************************Filters*********************/

  const columns = [
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.nombres.localeCompare(b.nombres),
      ...getColumnSearchProps("nombres"),
    },
    {
      title: "Apellidos",
      dataIndex: "apellidos",
      key: "apellidos",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.apellidos.localeCompare(b.apellidos),
      ...getColumnSearchProps("apellidos"),
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchProps("email"),
    },
    {
      title: "T. Usuario",
      dataIndex: "tuser",
      key: "tuser",
      render: (tUser) => {
        if (tUser === "Free") {
          return <Tag color="blue">{tUser}</Tag>;
        } else if (tUser === "Referido") {
          return <Tag color="magenta">{tUser}</Tag>;
        } else if (tUser === "Premium") {
          return <Tag color="volcano">{tUser}</Tag>;
        }
      },
      sorter: (a, b) => a.tuser.localeCompare(b.tuser),
      ...getColumnSearchProps("tuser"),
    },
    {
      title: "Activo Desde",
      dataIndex: "since",
      key: "since",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => new Date(a.since) - new Date(b.since),
    },
    {
      title: "Fecha nacimiento",
      dataIndex: "fechaNacimiento",
      key: "fechaNacimiento",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) =>
        new Date(a.fechaNacimiento) - new Date(b.fechaNacimiento),
    },
    {
      title: "Institución educativa",
      dataIndex: "institucion",
      key: "institucion",
      render: (text) => <p>{text}</p>,
      ...getColumnSearchProps("institucion"),
    },
    {
      title: "Fuegos",
      dataIndex: "fuegos",
      key: "fuegos",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.fuegos - b.fuegos,
    },
    {
      title: "M. Totales",
      dataIndex: "minutosTotales",
      key: "minutosTotales",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.minutosTotales - b.minutosTotales,
    },
    {
      title: "Estrellas",
      dataIndex: "estrellas",
      key: "estrellas",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.estrellas - b.estrellas,
    },
    {
      title: "Accion",
      key: "action",
      render: (i) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setVisible(true);
              //console.log(i)
              setId(i.userId);
              setNombres(i.nombres);
              setApellidos(i.apellidos);
              setEmail(i.email);
              setTuser(i.tuser);
              setSince(i.since);
              setFechaNacimiento(i.fechaNacimiento);
              setInstitucion(i.institucion);
              setFuegos(i.fuegos);
              setMinutosTotales(i.minutosTotales);
              setEstrellas(i.estrellas);
              setMinutos(i.minutos);
              setHabitos(i.habitos);
            }}
          />
          <DeleteOutlined onClick={() => showConfirm(i.userId)} />
        </Space>
      ),
    },
  ];

  const readData = async () => {
    let data2 = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data2 = await handleRead("usuarios");
    //console.log(data2)
    setData(data2);
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <Layout className="site-layout">
      <Header className="site-layout-background">
        <h2>Tabla de usuarios</h2>
      </Header>
      <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
      <Content>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Inicio</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <h2>Lista de {data.length} Usuarios</h2>
          <br></br>
          <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />
        </div>
        <Modal
          title="Editar usuario"
          centered
          visible={visible}
          onOk={() => {
            handleUpdate("usuarios", id, {
              nombres,
              apellidos,
              email,
              tuser,
              since,
              fechaNacimiento,
              institucion,
              fuegos,
              minutosTotales,
              estrellas,
            });
            setVisible(false);
            openNotification();
            readData();
          }}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          <h2>Información personal</h2>
          <div className="modal">
            <div>
              <p>Nombres usuario</p>
              <Input
                placeholder="Nombres"
                value={nombres}
                onChange={(event) => setNombres(event.target.value)}
              />
            </div>
            <div>
              <p>Apellidos usuario</p>
              <Input
                placeholder="Apellido de usuario"
                value={apellidos}
                onChange={(event) => setApellidos(event.target.value)}
              />
            </div>
            <div>
              <p>Correo</p>
              <Input
                placeholder="Correo"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div>
              <p>Institucion Educativa</p>
              <Input
                placeholder="Institucion educativa"
                value={institucion}
                onChange={(event) => setInstitucion(event.target.value)}
              />
            </div>
            <div>
              <p>Fecha de Nacimiento</p>
              <Input
                value={fechaNacimiento}
                type="date"
                onChange={(event) => setFechaNacimiento(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Activo desde</p>
              <Input
                value={since}
                type="date"
                onChange={(event) => setSince(event.target.value)}
              />
            </div>
            <div>
              <p>Fuegos</p>
              <Input
                placeholder="Fuegos"
                value={fuegos}
                onChange={(event) => setFuegos(event.target.value)}
              />
            </div>
            <div>
              <p>Minutos Totales</p>
              <Input
                placeholder="Minutos totales"
                value={minutosTotales}
                onChange={(event) => setMinutosTotales(event.target.value)}
              />
            </div>
            <div>
              <p>Estrellas</p>
              <Input
                placeholder="Estrellas"
                value={estrellas}
                onChange={(event) => setEstrellas(event.target.value)}
              />
            </div>
            <div>
              <p>Tipo de usuario</p>
              <Select
                defaultValue={tuser}
                style={{ width: 120 }}
                onChange={(value) => setTuser(value)}
                options={[
                  {
                    value: "Free",
                    label: "Free",
                  },
                  {
                    value: "Referido",
                    label: "Referido",
                  },
                  {
                    value: "Premium",
                    label: "Premium",
                  },
                ]}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Minutos por categoría semanal</p>
              <ul>
                <li>
                  {minutos[0]?.categoria} : {minutos[0]?.minutosSemana}
                </li>
                <li>
                  {minutos[1]?.categoria} : {minutos[1]?.minutosSemana}
                </li>
                <li>
                  {minutos[2]?.categoria} : {minutos[2]?.minutosSemana}
                </li>
                <li>
                  {minutos[3]?.categoria} : {minutos[3]?.minutosSemana}
                </li>
                <li>
                  {minutos[4]?.categoria} : {minutos[4]?.minutosSemana}
                </li>
              </ul>
            </div>
            <div>
              <p>Minutos por categoría mensual</p>
              <ul>
                <li>
                  {minutos[0]?.categoria} : {minutos[0]?.minutos}
                </li>
                <li>
                  {minutos[1]?.categoria} : {minutos[1]?.minutos}
                </li>
                <li>
                  {minutos[2]?.categoria} : {minutos[2]?.minutos}
                </li>
                <li>
                  {minutos[3]?.categoria} : {minutos[3]?.minutos}
                </li>
                <li>
                  {minutos[4]?.categoria} : {minutos[4]?.minutos}
                </li>
              </ul>
            </div>
            <div>
              <p>Hábitos</p>
              <ul>
                <li>Actividad física / 0,0,0,1,1,0,0 / 4</li>
                {habitos?.map((hab) =>
                  hab.selected ? (
                    <li>
                      {hab.name} / {hab.frecuencia.toString()} / {hab.dias}
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        </Modal>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Powered by Studially ©2023
      </Footer>
    </Layout>
  );
};

export default Inicio;
