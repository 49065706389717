import React, { useState, useEffect, useMemo, useRef } from "react";
import handleRead from "../../handlers/handleRead";
import handleSubmit from "../../handlers/handleSubmit";
import handleUpdate from "../../handlers/handleUpdate";
import handleDelete from "../../handlers/handleDelete";

import Highlighter from "react-highlight-words";

import { collection, getDocs, onSnapshot } from "@firebase/firestore";
import { firestore } from "../../firebaseConfig";


//style
import "antd/dist/antd.css";
import "./Enfoque.css";

import CreatableSelect from 'react-select/creatable';

//Antd
import {
  Layout,
  Breadcrumb,
  Input,
  Button,
  Table,
  Space,
  Modal,
  notification,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer } = Layout;
const { confirm } = Modal;

const Enfoque = () => {
  const [visibleEdit, setVisibleEdit] = useState(false);

  const [visibleNew, setVisibleNew] = useState(false);

  const [data, setData] = useState([]);

  // data to save
  const [idProducto, setIdProducto] = useState("");
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState(0);
  const [imagenURL, setImagenURL] = useState("");
  const [universidad, setUniversidad] = useState("");
  const [fecha, setFecha] = useState("");
  const [linkNoticia, setLinkNoticia] = useState("");

  // id to find it in database
  const [id, setId] = useState("");

  const Context = React.createContext({ name: "Default" });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type) => {
    if (type === "success") {
      api.success({
        message: `Operación exitosa`,
      });
    } else if (type === "warn") {
      api.warning({
        message: `Campos faltantes`,
      });
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  const showConfirm = (id) => {
    confirm({
      title: "¿Seguro que quieres eliminar al beneficio?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Los usuarios de Studially ya no tendrán acceso a este beneficio",
      onOk() {
        try {
          handleDelete("productosRewards", id);
          openNotification("success");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  /***************************Filters**********************/

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /***************************Filters*********************/

  const columns = [
    {
      title: "ID",
      dataIndex: "idProducto",
      key: "idProducto",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.idProducto.localeCompare(b.idProducto),
      ...getColumnSearchProps("idProducto"),
    },
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
      render: (text) => <p>{text}</p>,
      ...getColumnSearchProps("titulo"),
    },
    {
      title: "Imagen URL",
      dataIndex: "imagenURL",
      key: "imagenURL",
      render: (text) => <p className="truncate">{text}</p>,
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      render: (text) => <p className="truncate">{text}</p>,
      sorter: (a, b) => a.descripcion - b.descripcion,
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.fecha - b.fecha,
    },
    {
      title: "Accion",
      key: "action",
      render: (i) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setVisibleEdit(true);
              console.log(i);
              setId(i.id);
              setIdProducto(i.idProducto);
              setTitulo(i.titulo);
              setDescripcion(i.descripcion);
              setFecha(i.fecha);
              setImagenURL(i.imagenURL);
              setUniversidad(i.universidad);
              setLinkNoticia(i.linkNoticia);
            }}
          />
          <DeleteOutlined onClick={() => showConfirm(i.id)} />
        </Space>
      ),
    },
  ];

  const readData = () => {
    const col = collection(firestore, "productosRewards");
    const unsubscribe = onSnapshot(col, (snapshot) => {
        const list = snapshot.docs.map((doc) => doc.data());
        console.log(list);
        setData(list);
    });

    // Devolvemos la función `unsubscribe` desde el useEffect para limpiar el listener
    // cuando el componente se desmonte.
    return () => unsubscribe();
};

useEffect(() => {
    return readData();  // Nota que estamos devolviendo la función resultante de readData.
}, []);

  return (
    <Layout className="site-layout">
      <Header className="site-layout-background">
        <h2>Enfoque</h2>
      </Header>
      <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
      <Content>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Enfoque</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => setVisibleNew(true)}
          >
            Nuevo producto
          </Button>
          <h2>Lista de productos</h2>
          <br></br>
          <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />
        </div>
        <Modal
          title="Editar producto"
          centered
          visible={visibleEdit}
          onOk={() => {
            handleUpdate("productosRewards", id, {
              idProducto,
              titulo,
              descripcion,
              fecha,
              imagenURL,
              universidad,
              linkNoticia
            });
            setVisibleEdit(false);
            openNotification("success");
          }}
          onCancel={() => setVisibleEdit(false)}
          width={1000}
        >
          <div className="modal">
            <div>
              <p>ID producto</p>
              <Input
                value={idProducto}
                onChange={(event) => setIdProducto(event.target.value)}
              />
            </div>
            <div>
              <p>Titulo</p>
              <Input
                value={titulo}
                onChange={(event) => setTitulo(event.target.value)}
              />
            </div>
            <div>
              <p>Imagen URL</p>
              <Input
                value={imagenURL}
                onChange={(event) => setImagenURL(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Descripcion</p>
              <textarea
                value={descripcion}
                onChange={(event) => setDescripcion(event.target.value)}
                className="textarea_custom"
              >
                </textarea>
            </div>
            <div>
              <p>Fecha</p>
              <Input
                value={fecha}
                type="date"
                onChange={(event) => setFecha(event.target.value)}
              />
            </div>
            <div>
              <p>Institución</p>
              <CreatableSelect
                isClearable
                defaultValue={{ value: universidad, label: universidad }}
                styles={{ container: (provided) => ({ ...provided, width: 220 }) }}
                onChange={(selectedOption) => setUniversidad(selectedOption ? selectedOption.value : null)}
                options={[
                  { value: 'Studially', label: 'Studially' },
                  { value: 'Panamericana CDMX', label: 'Panamericana CDMX' },
                  { value: 'La Salle México', label: 'La Salle México' },
                  { value: 'La Salle Pachuca', label: 'La Salle Pachuca' },
                  { value: 'La Salle Baiío León', label: 'La Salle Baiío León' },
                  { value: 'La Salle Benavente Puebla', label: 'La Salle Benavente Puebla' },
                  { value: 'La Salle Cuernavaca', label: 'La Salle Cuernavaca' },
                  { value: 'La Salle Cancún', label: 'La Salle Cancún' },
                  { value: 'La Salle Chihuahua', label: 'La Salle Chihuahua' },
                  { value: 'La Salle Laguna', label: 'La Salle Laguna' },
                  { value: 'La Salle Victoria', label: 'La Salle Victoria' },
                  { value: 'La Salle Morelia', label: 'La Salle Morelia' },
                  { value: 'La Salle Nezahualcóyotl', label: 'La Salle Nezahualcóyotl' },
                  { value: 'La Salle Noroeste', label: 'La Salle Noroeste' },
                  { value: 'La Salle Oaxaca', label: 'La Salle Oaxaca' },
                  { value: 'La Salle Saltillo', label: 'La Salle Saltillo' },
                  { value: 'UVM CDMX', label: 'UVM CDMX' },
                  { value: 'UNITEC CDMX', label: 'UNITEC CDMX' },
                  { value: 'Justo Sierra CDMX', label: 'Justo Sierra CDMX' },
                  { value: 'ITESM CDMX', label: 'ITESM CDMX' },
                  { value: 'ITESM EDOMEX', label: 'ITESM EDOMEX' },
                  { value: 'UNAM', label: 'UNAM' },
                  { value: 'IPN', label: 'IPN' },
                  { value: 'Otra', label: 'Otra' }
                ]}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Link de la Noticia</p>
              <Input
                value={linkNoticia}
                onChange={(event) => setLinkNoticia(event.target.value)}
             / >
            </div>
          </div>
          <br></br>
        </Modal>

        <Modal
          title="Nuevo producto"
          centered
          visible={visibleNew}
          onOk={() => {
            handleSubmit("productosRewards", {
              idProducto,
              titulo,
              descripcion,
              fecha,
              imagenURL,
              universidad,
              linkNoticia
            });
            setVisibleNew(false);
            if (
              idProducto === "" ||
              titulo === "" ||
              descripcion === "" ||
              fecha === "" ||
              imagenURL === "" ||
              universidad === "" ||
              linkNoticia === ""
            ) {
              openNotification("warn");
            } else {
              openNotification("success");
            }
          }}
          onCancel={() => setVisibleNew(false)}
          destroyOnClose={true}
          width={1000}
        >
          <div className="modal">
            <div>
              <p>ID producto</p>
              <Input
                placeholder="Id de producto"
                onChange={(event) => setIdProducto(event.target.value)}
              />
            </div>
            <div>
              <p>Titulo</p>
              <Input
                placeholder="Titulo"
                onChange={(event) => setTitulo(event.target.value)}
              />
            </div>
            <div>
              <p>Imagen URL</p>
              <Input
                placeholder="Imagen URL"
                onChange={(event) => setImagenURL(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Descripcion</p>
              <textarea
                placeholder="Descripcion"
                onChange={(event) => setDescripcion(event.target.value)}
                >
                  </textarea>
            </div>
            <div>
              <p>Fecha</p>
              <Input
                value={fecha}
                type="date"
                onChange={(event) => setFecha(event.target.value)}
              />
            </div>
            <div>
              <p>Institución</p>
              <CreatableSelect
                isClearable
                defaultValue={{ value: universidad, label: universidad }}
                styles={{ container: (provided) => ({ ...provided, width: 220 }) }}
                onChange={(selectedOption) => setUniversidad(selectedOption ? selectedOption.value : null)}
                options={[
                  { value: 'Studially', label: 'Studially' },
                  { value: 'Panamericana CDMX', label: 'Panamericana CDMX' },
                  { value: 'La Salle México', label: 'La Salle México' },
                  { value: 'La Salle Pachuca', label: 'La Salle Pachuca' },
                  { value: 'La Salle Baiío León', label: 'La Salle Baiío León' },
                  { value: 'La Salle Benavente Puebla', label: 'La Salle Benavente Puebla' },
                  { value: 'La Salle Cuernavaca', label: 'La Salle Cuernavaca' },
                  { value: 'La Salle Cancún', label: 'La Salle Cancún' },
                  { value: 'La Salle Chihuahua', label: 'La Salle Chihuahua' },
                  { value: 'La Salle Laguna', label: 'La Salle Laguna' },
                  { value: 'La Salle Victoria', label: 'La Salle Victoria' },
                  { value: 'La Salle Morelia', label: 'La Salle Morelia' },
                  { value: 'La Salle Nezahualcóyotl', label: 'La Salle Nezahualcóyotl' },
                  { value: 'La Salle Noroeste', label: 'La Salle Noroeste' },
                  { value: 'La Salle Oaxaca', label: 'La Salle Oaxaca' },
                  { value: 'La Salle Saltillo', label: 'La Salle Saltillo' },
                  { value: 'UVM CDMX', label: 'UVM CDMX' },
                  { value: 'UNITEC CDMX', label: 'UNITEC CDMX' },
                  { value: 'Justo Sierra CDMX', label: 'Justo Sierra CDMX' },
                  { value: 'ITESM CDMX', label: 'ITESM CDMX' },
                  { value: 'ITESM EDOMEX', label: 'ITESM EDOMEX' },
                  { value: 'UNAM', label: 'UNAM' },
                  { value: 'IPN', label: 'IPN' },
                  { value: 'Otra', label: 'Otra' }
                ]}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Link de la Noticia</p>
              <Input
                value={linkNoticia}
                onChange={(event) => setLinkNoticia(event.target.value)}
             / >
            </div>
          </div>
          <br></br>
        </Modal>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Powered by Studially ©2023
      </Footer>
    </Layout>
  );
};

export default Enfoque;
