// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAFvVYzzKM2yl3WVP6we6RFSsXc8flHfFs',
  authDomain: 'studially-2790e.firebaseapp.com',
  projectId: 'studially-2790e',
  storageBucket: 'studially-2790e.appspot.com',
  messagingSenderId: '498493727897',
  appId: '1:498493727897:web:c6af7dfcbdbb151f1e8408',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const firestore = getFirestore(app)
export const auth = getAuth(app)
export const functions = getFunctions(app)
