import React, { useState, useEffect, useMemo, useRef } from "react";
import handleRead from "../../handlers/handleRead";
import handleSubmit from "../../handlers/handleSubmit";
import handleUpdate from "../../handlers/handleUpdate";
import handleDelete from "../../handlers/handleDelete";

import Highlighter from "react-highlight-words";

import CreatableSelect from 'react-select/creatable';

//style
import "antd/dist/antd.css";
import "./Comunidad.css";

//Antd
import {
  Layout,
  Breadcrumb,
  Input,
  Button,
  Table,
  Space,
  Modal,
  Select,
  notification,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer } = Layout;
const { confirm } = Modal;
const { TextArea } = Input;

const Comunidad = () => {
  const [visibleEdit, setVisibleEdit] = useState(false);

  const [visibleNew, setVisibleNew] = useState(false);

  const [data, setData] = useState([]);

  // data to save
  const [titulo, setTitulo] = useState("");
  const [etiqueta, setEtiqueta] = useState("");
  const [universidad, setUniversidad] = useState("");
  const [texto, setTexto] = useState("");
  const [fecha, setFecha] = useState("");
  const [url, setUrl] = useState("");
  const [imagenURL, setImagenURL] = useState("");

  // id to find it in database
  const [id, setId] = useState("");

  const Context = React.createContext({ name: "Default" });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type) => {
    if (type === "success") {
      api.success({
        message: `Operación exitosa`,
      });
    } else if (type === "warn") {
      api.warning({
        message: `Campos faltantes`,
      });
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  const showConfirm = (id) => {
    confirm({
      title: "¿Seguro que quieres eliminar la publicación?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Los usuarios de Studially ya no tendrán acceso a esta publicación",
      onOk() {
        try {
          handleDelete("comunidad", id);
          openNotification("success");
          readData();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  /***************************Filters**********************/

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /***************************Filters*********************/

  const columns = [
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.titulo.localeCompare(b.titulo),
      ...getColumnSearchProps("titulo"),
    },
    {
      title: "Universidad",
      dataIndex: "universidad",
      key: "universidad",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.universidad.localeCompare(b.universidad),
      ...getColumnSearchProps("universidad"),
    },
    {
      title: "Etiqueta",
      dataIndex: "etiqueta",
      key: "etiqueta",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.etiqueta.localeCompare(b.etiqueta),
      ...getColumnSearchProps("etiqueta"),
    },
    {
      title: "Accion",
      key: "action",
      render: (i) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setVisibleEdit(true);
              console.log(i);
              setId(i.id);
              setTitulo(i.titulo);
              setUniversidad(i.universidad);
              setEtiqueta(i.etiqueta);
              setTexto(i.texto);
              setUrl(i.url);
              setImagenURL(i.imagenURL);
              setFecha(i.fecha);
            }}
          />
          <DeleteOutlined onClick={() => showConfirm(i.id)} />
        </Space>
      ),
    },
  ];

  const readData = async () => {
    let data2 = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data2 = await handleRead("comunidad");
    console.log(data2);
    setData(data2);
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <Layout className="site-layout">
      <Header className="site-layout-background">
        <h2>Comunidad</h2>
      </Header>
      <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
      <Content>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Comunidad</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => setVisibleNew(true)}
          >
            Nueva publicación
          </Button>
          <h2>Lista de publicaciones</h2>
          <br></br>
          <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />
        </div>
        <Modal
          title="Editar publicación"
          centered
          visible={visibleEdit}
          onOk={() => {
            handleUpdate("comunidad", id, {
              titulo,
              imagenURL,
              universidad,
              etiqueta,
              url,
              texto,
              fecha,
            });
            setVisibleEdit(false);
            openNotification("success");
            readData();
          }}
          onCancel={() => setVisibleEdit(false)}
          width={1000}
        >
          <div className="modal">
            <div>
              <p>Titulo</p>
              <Input
                value={titulo}
                onChange={(event) => setTitulo(event.target.value)}
              />
            </div>
            <div>
              <p>Institución</p>
              <CreatableSelect
                isClearable
                defaultValue={{ value: universidad, label: universidad }}
                styles={{ container: (provided) => ({ ...provided, width: 220 }) }}
                onChange={(selectedOption) => setUniversidad(selectedOption ? selectedOption.value : null)}
                options={[
                  { value: 'Studially', label: 'Studially' },
                  { value: 'Panamericana CDMX', label: 'Panamericana CDMX' },
                  { value: 'La Salle México', label: 'La Salle México' },
                  { value: 'La Salle Pachuca', label: 'La Salle Pachuca' },
                  { value: 'La Salle Baiío León', label: 'La Salle Baiío León' },
                  { value: 'La Salle Benavente Puebla', label: 'La Salle Benavente Puebla' },
                  { value: 'La Salle Cuernavaca', label: 'La Salle Cuernavaca' },
                  { value: 'La Salle Cancún', label: 'La Salle Cancún' },
                  { value: 'La Salle Chihuahua', label: 'La Salle Chihuahua' },
                  { value: 'La Salle Laguna', label: 'La Salle Laguna' },
                  { value: 'La Salle Victoria', label: 'La Salle Victoria' },
                  { value: 'La Salle Morelia', label: 'La Salle Morelia' },
                  { value: 'La Salle Nezahualcóyotl', label: 'La Salle Nezahualcóyotl' },
                  { value: 'La Salle Noroeste', label: 'La Salle Noroeste' },
                  { value: 'La Salle Oaxaca', label: 'La Salle Oaxaca' },
                  { value: 'La Salle Saltillo', label: 'La Salle Saltillo' },
                  { value: 'UVM CDMX', label: 'UVM CDMX' },
                  { value: 'UNITEC CDMX', label: 'UNITEC CDMX' },
                  { value: 'Justo Sierra CDMX', label: 'Justo Sierra CDMX' },
                  { value: 'ITESM CDMX', label: 'ITESM CDMX' },
                  { value: 'ITESM EDOMEX', label: 'ITESM EDOMEX' },
                  { value: 'UNAM', label: 'UNAM' },
                  { value: 'IPN', label: 'IPN' },
                  { value: 'Otra', label: 'Otra' }
                ]}
              />
            </div>
            <div>
              <p>Imagen</p>
              <Input
                value={imagenURL}
                onChange={(event) => setImagenURL(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Etiqueta</p>
              <Input
                placeholder="Titulo de comunidad"
                onChange={(event) => setEtiqueta(event.target.value)}
              />
            </div>
            <div>
              <p>Fecha</p>
              <Input
                value={fecha}
                type="date"
                onChange={(event) => setFecha(event.target.value)}
              />
            </div>
            <div>
              <p>URL</p>
              <Input
                value={url}
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <p>Cuerpo de publicación</p>
          <TextArea
            showCount
            maxLength={1500}
            style={{ height: 120 }}
            value={texto}
            onChange={(event) => setTexto(event.target.value)}
          />
        </Modal>

        <Modal
          title="Nueva publicación"
          centered
          visible={visibleNew}
          onOk={() => {
            handleSubmit("comunidad", {
              etiqueta,
              universidad,
              titulo,
              texto,
              fecha,
              url,
              imagenURL,
            });
            setVisibleNew(false);
            if (
              etiqueta === "" ||
              universidad === "" ||
              titulo === "" ||
              texto === "" ||
              fecha === "" ||
              url === "" ||
              imagenURL === ""
            ) {
              openNotification("warn");
            } else {
              openNotification("success");
            }
            readData();
          }}
          onCancel={() => setVisibleNew(false)}
          destroyOnClose={true}
          width={1000}
        >
          <div className="modal">
            <div>
              <p>Titulo</p>
              <Input
                placeholder="Titulo de comunidad"
                onChange={(event) => setTitulo(event.target.value)}
              />
            </div>
            <div>
              <p>Institución</p>
              <CreatableSelect
                isClearable
                defaultValue={{ value: 'Otra', label: 'Otra' }}
                styles={{ container: (provided) => ({ ...provided, width: 220 }) }}
                onChange={(selectedOption) => setUniversidad(selectedOption ? selectedOption.value : null)}
                options={[
                  { value: 'Studially', label: 'Studially' },
                  { value: 'Panamericana CDMX', label: 'Panamericana CDMX' },
                  { value: 'La Salle México', label: 'La Salle México' },
                  { value: 'La Salle Pachuca', label: 'La Salle Pachuca' },
                  { value: 'La Salle Baiío León', label: 'La Salle Baiío León' },
                  { value: 'La Salle Benavente Puebla', label: 'La Salle Benavente Puebla' },
                  { value: 'La Salle Cuernavaca', label: 'La Salle Cuernavaca' },
                  { value: 'La Salle Cancún', label: 'La Salle Cancún' },
                  { value: 'La Salle Chihuahua', label: 'La Salle Chihuahua' },
                  { value: 'La Salle Laguna', label: 'La Salle Laguna' },
                  { value: 'La Salle Victoria', label: 'La Salle Victoria' },
                  { value: 'La Salle Morelia', label: 'La Salle Morelia' },
                  { value: 'La Salle Nezahualcóyotl', label: 'La Salle Nezahualcóyotl' },
                  { value: 'La Salle Noroeste', label: 'La Salle Noroeste' },
                  { value: 'La Salle Oaxaca', label: 'La Salle Oaxaca' },
                  { value: 'La Salle Saltillo', label: 'La Salle Saltillo' },
                  { value: 'UVM CDMX', label: 'UVM CDMX' },
                  { value: 'UNITEC CDMX', label: 'UNITEC CDMX' },
                  { value: 'Justo Sierra CDMX', label: 'Justo Sierra CDMX' },
                  { value: 'ITESM CDMX', label: 'ITESM CDMX' },
                  { value: 'ITESM EDOMEX', label: 'ITESM EDOMEX' },
                  { value: 'UNAM', label: 'UNAM' },
                  { value: 'IPN', label: 'IPN' },
                  { value: 'Otra', label: 'Otra' }
                ]}
              />
            </div>
            <div>
              <p>Imagen</p>
              <Input
                placeholder="Imagen"
                onChange={(event) => setImagenURL(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <div className="modal">
            <div>
              <p>Etiqueta</p>
              <Input
                placeholder="Titulo de comunidad"
                onChange={(event) => setEtiqueta(event.target.value)}
              />
            </div>
            <div>
              <p>Fecha</p>
              <Input
                placeholder="fecha"
                type="date"
                onChange={(event) => setFecha(event.target.value)}
              />
            </div>
            <div>
              <p>URL</p>
              <Input
                placeholder="Url"
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <p>Cuerpo de publicación</p>
          <TextArea
            showCount
            maxLength={1500}
            style={{ height: 120 }}
            onChange={(event) => setTexto(event.target.value)}
          />
        </Modal>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Powered by Studially ©2023
      </Footer>
    </Layout>
  );
};

export default Comunidad;
