import React, { useState } from 'react'

//style
import 'antd/dist/antd.css'
import './Notificaciones.css'

//Antd
import {
  Layout,
  Breadcrumb,
  Input,
  Button,
  Tag,
  Table,
  Space,
  DatePicker,
  Modal,
  Select,
  Form,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'

import { getFunctions, httpsCallable } from 'firebase/functions'

const { Header, Content, Footer } = Layout
const { Search } = Input
const { confirm } = Modal
const { TextArea } = Input
const { Option } = Select

const Notificaciones = () => {
  const [visibleEdit, setVisibleEdit] = useState(false)

  const [visibleNew, setVisibleNew] = useState(false)

  const showConfirm = () => {
    confirm({
      title: '¿Seguro que quieres eliminar la notificación?',
      icon: <ExclamationCircleOutlined />,
      content: 'Los usuarios de Studially ya no recibirán esta notificación',
      onOk() {
        console.log('OK')
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const columns = [
    {
      title: 'Nombres',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'T. Usuario',
      dataIndex: 'tUser',
      key: 'tUser',
      render: (tUser) => {
        if (tUser === 'Free') {
          return <Tag color="blue">{tUser}</Tag>
        } else if (tUser === 'Referido') {
          return <Tag color="magenta">{tUser}</Tag>
        } else if (tUser === 'Premium') {
          return <Tag color="volcano">{tUser}</Tag>
        }
      },
    },
    {
      title: 'Fecha y Hora',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Accion',
      key: 'action',
      render: () => (
        <Space size="middle">
          <EditOutlined onClick={() => setVisibleEdit(true)} />
          <DeleteOutlined onClick={() => showConfirm()} />
        </Space>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Titulo Notificaciones 1',
      date: '13/08/22',
      tUser: 'Free',
    },
    {
      key: '2',
      name: 'Titulo Notificaciones 2',
      date: '28/05/22',
      tUser: 'Referido',
    },
    {
      key: '3',
      name: 'Titulo Notificaciones 3',
      date: '13/04/22',
      tUser: 'Premium',
    },
    {
      key: '4',
      name: 'Titulo Notificaciones 4',
      date: '22/12/22',
      tUser: 'Free',
    },
  ]

  const onSearch = (value) => console.log(value)

  const onChangeDate = (value) => console.log('Fecha ' + value)

  const onChangeDateModal = (value) => console.log('Fecha ' + value)

  const handleChange = (value) => console.log('Selected ' + value)

  const sendNotification = (data) => {
    const functions = getFunctions()
    const send = httpsCallable(functions, 'sendNotification')
    send(data)
      .then(() => console.log('sent'))
      .catch((error) => console.error(error))
  }

  const [form] = Form.useForm()

  const onFinish = (values) => {
    sendNotification(values)
    setVisibleNew(false)
  }

  const NotificationForm = () => (
    <Form
      layout={{ labelCol: { span: 8 }, wrapperCol: { span: 16 } }}
      form={form}
      name="send-notification"
      onFinish={onFinish}
      style={{ maxWidth: 600 }}
      initialValues={{ topic: 'all' }}>
      <Form.Item
        name="title"
        label="Titulo de notificación"
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="topic"
        label="Tipo de usuario"
        rules={[{ required: true }]}>
        <Select placeholder="Selecciona un tipo de usuario">
          <Option value="all">Todos</Option>
          <Option value="free">Free</Option>
          <Option value="premium">Premium</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="body"
        label="Cuerpo de notificación"
        rules={[{ required: true }]}>
        <Input.TextArea showCount maxLength={300} />
      </Form.Item>
    </Form>
  )

  return (
    <Layout className="site-layout">
      <Header className="site-layout-background">
        <h2>Notificaciones</h2>
      </Header>
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Notificaciones</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}>
          <Button
            type="primary"
            style={{ float: 'right' }}
            onClick={() => setVisibleNew(true)}>
            Nueva notificación
          </Button>
          <h2>Lista de Notificaciones</h2>
          <h2>Filtros</h2>
          <div className="filtros">
            <div>
              <p>Nombre</p>
              <Search
                placeholder="Escribe el nombre de la notificación"
                allowClear
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </div>
            <div>
              <p>Fecha de publicación</p>
              <DatePicker onChange={onChangeDate} />
            </div>
            <div>
              <Button type="primary">Reestablecer</Button>
            </div>
          </div>
          <br></br>
          <Table columns={columns} dataSource={data} />
        </div>
        <Modal
          title="Editar notificación"
          centered
          visible={visibleEdit}
          onOk={() => setVisibleEdit(false)}
          onCancel={() => setVisibleEdit(false)}
          width={1000}>
          <div className="modal">
            <div>
              <p>Titulo</p>
              <Input placeholder="Titulo de notificación" />
            </div>
            <div>
              <p>Fecha y hora de publicación</p>
              <DatePicker
                onChange={onChangeDateModal}
                format="YYYY-MM-DD HH:mm:ss"
                showTime="true"
              />
            </div>
            <div>
              <p>Usuario Objetivo</p>
              <Select
                style={{ width: '200px' }}
                placeholder="Selecciona un tipo"
                onChange={handleChange}>
                <Option value="free">Free</Option>
                <Option value="referido">Referido</Option>
                <Option value="premium">Premium</Option>
              </Select>
            </div>
          </div>
          <br></br>
          <p>Cuerpo de notificación</p>
          <TextArea showCount maxLength={300} style={{ height: 120 }} />
        </Modal>

        <Modal
          title="Nueva notificación"
          centered
          visible={visibleNew}
          onOk={() => form.submit()}
          onCancel={() => setVisibleNew(false)}
          width={1000}>
          <div className="modal">
            <NotificationForm />
          </div>
        </Modal>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Powered by Studially ©2023
      </Footer>
    </Layout>
  )
}

export default Notificaciones
