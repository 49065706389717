import React, { useState, useEffect, useMemo, useRef } from "react";
import handleRead from "../../handlers/handleRead";
import handleSubmit from "../../handlers/handleSubmit";
import handleUpdate from "../../handlers/handleUpdate";
import handleDelete from "../../handlers/handleDelete";

import Highlighter from "react-highlight-words";

//style
import "antd/dist/antd.css";
import "./Habitos.css";

//Antd
import {
  Layout,
  Breadcrumb,
  Input,
  Button,
  Table,
  Space,
  Modal,
  notification,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer } = Layout;
const { confirm } = Modal;
const { TextArea } = Input;

const Habitos = () => {
  const [visibleEdit, setVisibleEdit] = useState(false);

  const [visibleNew, setVisibleNew] = useState(false);

  const [data, setData] = useState([]);

  // data to save
  const [name, setName] = useState("");
  const [icono, setIcono] = useState("");
  const [texto, setTexto] = useState("");
  const frecuencia = [0, 0, 0, 0, 0, 0, 0];
  const selected = false;
  const marcadoSemana = [];
  const marcadoMes = [];

  // id to find it in database
  const [id, setId] = useState("");

  const Context = React.createContext({ name: "Default" });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type) => {
    if (type === "success") {
      api.success({
        message: `Operación exitosa`,
      });
    } else if (type === "warn") {
      api.warning({
        message: `Campos faltantes`,
      });
    }
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  const showConfirm = (id) => {
    confirm({
      title: "¿Seguro que quieres eliminar el hábito?",
      icon: <ExclamationCircleOutlined />,
      content: "Los usuarios de Studially ya no tendrán acceso a este hábito",
      onOk() {
        try {
          handleDelete("habitosApp", id);
          openNotification("success");
          readData();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  /***************************Filters**********************/

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /***************************Filters*********************/

  const columns = [
    {
      title: "Nombres",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Icono",
      dataIndex: "icono",
      key: "icono",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Accion",
      key: "action",
      render: (i) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setVisibleEdit(true);
              console.log(i);
              setId(i.id);
              setName(i.name);
              setIcono(i.icono);
              setTexto(i.texto);
            }}
          />
          <DeleteOutlined onClick={() => showConfirm(i.id)} />
        </Space>
      ),
    },
  ];

  const readData = async () => {
    let data2 = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data2 = await handleRead("habitosApp");
    console.log(data2);
    setData(data2);
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <Layout className="site-layout">
      <Header className="site-layout-background">
        <h2>Hábitos</h2>
      </Header>
      <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
      <Content>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Hábitos</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => setVisibleNew(true)}
          >
            Nuevo Hábito
          </Button>
          <h2>Lista de hábitos</h2>
          <br></br>
          <Table columns={columns} dataSource={data} scroll={{ x: 'max-content' }} />
        </div>
        <Modal
          title="Editar hábito"
          centered
          visible={visibleEdit}
          onOk={() => {
            handleUpdate("habitosApp", id, {
              name,
              icono,
              texto,
              frecuencia,
              selected,
              marcadoMes,
              marcadoSemana,
            });
            setVisibleEdit(false);
            openNotification("success");
            readData();
          }}
          onCancel={() => setVisibleEdit(false)}
          width={1000}
        >
          <div className="modal">
            <div>
              <p>Titulo</p>
              <Input
                placeholder="Nombre de hábito"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div>
              <p>Ícono</p>
              <Input
                placeholder="icono"
                value={icono}
                onChange={(event) => setIcono(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <p>Cuerpo de hábito</p>
          <TextArea
            showCount
            maxLength={1500}
            style={{ height: 120 }}
            value={texto}
            onChange={(event) => setTexto(event.target.value)}
          />
        </Modal>
        <Modal
          title="Nuevo hábito"
          centered
          visible={visibleNew}
          onOk={() => {
            handleSubmit("habitosApp", {
              name,
              icono,
              texto,
              frecuencia,
              selected,
              marcadoMes,
              marcadoSemana,
            });
            setVisibleNew(false);
            if (name === "" || icono === "" || texto === "") {
              openNotification("warn");
            } else {
              openNotification("success");
            }
            readData();
          }}
          onCancel={() => setVisibleNew(false)}
          destroyOnClose={true}
          width={1000}
        >
          <div className="modal">
            <div>
              <p>Titulo</p>
              <Input
                placeholder="Titulo de hábito"
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div>
              <p>Ícono</p>
              <Input
                placeholder="icono"
                onChange={(event) => setIcono(event.target.value)}
              />
            </div>
          </div>
          <br></br>
          <p>Cuerpo de hábito</p>
          <TextArea
            showCount
            maxLength={1500}
            style={{ height: 120 }}
            onChange={(event) => setTexto(event.target.value)}
          />
        </Modal>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Powered by Studially ©2023
      </Footer>
    </Layout>
  );
};

export default Habitos;
