import { updateDoc, doc } from "@firebase/firestore";
import { firestore } from "../firebaseConfig";
const handleUpdate = (coll, id, info) => {
  const ref = doc(firestore, coll, id); // Firebase creates this automatically
  let data = info;
  try {
    updateDoc(ref, data);
  } catch (err) {
    console.log(err);
  }
};
export default handleUpdate;
