import { collection, getDocs } from "@firebase/firestore";
import { firestore } from "../firebaseConfig";

const handleRead = async (coll) => {
  const col = collection(firestore, coll);
  try {
    const snapshot = await getDocs(col);
    const list = snapshot.docs.map((doc) => doc.data());
    return list;
  } catch (err) {
    console.log(err);
  }
};
export default handleRead;
